import React from 'react';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet 
        script={[{ 
            type: 'text/javascript', 
            innerHTML: "window.location.href='https://adamliu-app.notion.site/Audio-Looper-FAQ-bab4ee67758248349f12ee6078a6f57b'"
        }]} 
        />
    </>
  );
};

